import { useEffect, useMemo, useState } from "react";

import Dropdown from "../Dropdown/Dropdown";
import { NSDropdown } from "../Dropdown/Dropdown.type";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import { NSDropdownWithCaptionStyle } from "./DropdownWithCaption.style";
import { NSDropdownWithCaptionType } from "./DropdownWithCaption.type";
import { useLocation } from "react-router-dom";

function DropdownWithCaption({
  placeholder,
  prefixTitle,
  onCheckedItems,
  id,
  label,
  title,
  options,
  type,
  containAddNewItemButton,
  showLabel,
  selectedDropdownItem,
  onSelect,
  defaultCheckedItems,
}: NSDropdownWithCaptionType.IDropdownWithCaption) {
  const [selected, setSelectedItem] = useState<
    NSDropdown.IDropdownItem | undefined
  >(selectedDropdownItem);
  const [checkedItems, setCheckedItems] = useState<
    NSDropdown.IDropdownItem[] | undefined
  >(defaultCheckedItems);
  const location = useLocation();

  const onCheckedDropdownWithCaption = (
    checkedItemsProps: NSDropdown.IDropdownItem[]
  ) => {
    setCheckedItems(checkedItemsProps);
    onCheckedItems?.(checkedItemsProps);
  };

  const dropdownTitle = useMemo(
    () => (
      <>
        <NSDropdownWithCaptionStyle.PrefixContainer>
          <NSDropdownWithCaptionStyle.Caption>
            {prefixTitle}
          </NSDropdownWithCaptionStyle.Caption>
          <NSDropdownWithCaptionStyle.Seperator />
        </NSDropdownWithCaptionStyle.PrefixContainer>

        <NSDropdownStyle.Placeholder>
          {type === "basic" && selected ? selected.label : placeholder}
        </NSDropdownStyle.Placeholder>
      </>
    ),
    [placeholder, prefixTitle, type, selected, defaultCheckedItems]
  );

  const suffixItem = useMemo(
    () =>
      type === "withCheckbox" &&
      checkedItems &&
      checkedItems?.length > 0 && (
        <NSDropdownWithCaptionStyle.Badge>
          {checkedItems.length}
        </NSDropdownWithCaptionStyle.Badge>
      ),

    [checkedItems, type]
  );
  useEffect(() => {
    setCheckedItems(defaultCheckedItems);
  }, [location.pathname, setCheckedItems]);

  const onSelectItem = (selectedProps?: NSDropdown.IDropdownItem) => {
    setSelectedItem(selectedProps);
    onSelect?.(id, selectedProps);
  };

  useEffect(() => {
    onSelectItem(selectedDropdownItem);
  }, [selectedDropdownItem]);

  return (
    <NSDropdownWithCaptionStyle.Container>
      <Dropdown
        title={title}
        showLabel={showLabel}
        options={options}
        type={type}
        containAddNewItemButton={containAddNewItemButton}
        label={label}
        id={id}
        showSuffixItems
        onCheckedItems={onCheckedDropdownWithCaption}
        onSelect={(_id, selectedItem) => onSelectItem(selectedItem)}
        titleWithPrefixItem={dropdownTitle}
        suffixItem={suffixItem}
        defaultCheckedItems={defaultCheckedItems}
      />
    </NSDropdownWithCaptionStyle.Container>
  );
}

export default DropdownWithCaption;
