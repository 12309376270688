import styled from "styled-components";
import { NSDropdownStyle } from "../../../../components/Dropdown/Dropdown.style";
import { NSTableListStyle } from "../../../../components/TableList/TableList.style";
import { NSSolidButtonStyle } from "../../../../components/SolidButton/SolidButton.style";
import { NSMainButtonStyle } from "../../../../components/MainButton/MainButton.style";
import MainButton from "../../../../components/MainButton/MainButton";
import { NSContextMenuStyle } from "../../../../components/ContextMenu/ContextMenu.style";
import { NSNoteCardStyle } from "../../../../components/NoteCard/NoteCard.style";
import { NSHistoryTooltipStyle } from "../../../../components/HistoryTooltip/HistoryTooltip.style";
import { NSContextMenuHOCStyle } from "../../../../components/ContextMenuHOC/ContextMenuHOC.style";

export namespace NSCandidateListStyle {
  export const Container = styled.div`
    width: 100%;
    height: calc(100% - 140px);
  `;

  export const ButtonContainer = styled.div`
    width: 100%;
    ${NSMainButtonStyle.ButtonBase} {
      width: max-content;
    }
  `;

  export const HeaderCheckboxContainer = styled.div`
    position: absolute;
    top: 12px;
  `;

  export const DownloadListButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ${NSContextMenuHOCStyle.Container} {
      justify-content: flex-end;
    }
  `;

  export const DownloadListButton = styled(MainButton)`
    background: none;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 16px 8px;
  `;

  export const TabbedViewContainer = styled.div`
    width: 100%;
    height: calc(100% - 88px);
  `;

  export const OverviewContainer = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
  `;

  export const LongListContainer = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;

    ${NSTableListStyle.Base} {
      height: fit-content;
    }

    ${NSTableListStyle.Column} {
      width: max-content;
    }

    ${NSSolidButtonStyle.SolidButtonBase} {
      svg {
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }
  `;

  export const OverviewLeftArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    height: 100%;
  `;

  export const ProjectName = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  export const ProjectNameTooltipContainer = styled.div`
    display: flex;
    gap: 8px;
  `;

  export const Score = styled.div`
    background: ${(props) => props.theme.colors.shadesOfdark.dark06};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    border-radius: 8px;
    width: 41px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const SecondaryTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const Description = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin: 8px 0 32px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const SummaryContent = styled.div`
    margin-top: -48px;
    border-left: 1px solid ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const UploadExcelButton = styled(MainButton)`
    background-color: ${(props) => props.theme.colors.light.light};
    color: ${(props) => props.theme.colors.primary.primary};
    border: 2px solid ${(props) => props.theme.colors.neutrals.grey04};
  `;

  export const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
  `;

  export const AddCandidateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
  `;

  export const AddCandidateContainerText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const NotFoundText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin: 8px 0 32px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const NotFoundUpper = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const TableListContainer = styled.div`
    display: flex;
    position: relative;
    max-height: calc(100% - 32px);

    ${NSContextMenuStyle.InnerComponent} {
      :last-child {
        ${NSContextMenuStyle.ItemLabel} {
          color: ${(props) => props.theme.colors.error.red01};
        }

        svg {
          path {
            stroke: ${(props) => props.theme.colors.error.red01};
          }
        }

        :hover {
          ${NSContextMenuStyle.ItemLabel} {
            color: ${(props) => props.theme.colors.error.red01};
          }

          svg {
            path {
              stroke: ${(props) => props.theme.colors.error.red01};
            }
          }
        }
      }
    }

    ${NSContextMenuStyle.ContextMenuContent} {
      right: 64px;
    }

    ${NSTableListStyle.Base} {
      padding: 0 16px 40px;
      margin-top: 16px;
      min-height: 496px;
    }

    ${NSTableListStyle.HeaderColumn} {
      :nth-child(2) {
        padding-left: 0;
      }

      :nth-last-child(2) {
        min-width: max-content;
      }
    }

    ${NSTableListStyle.Header} {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    ${NSTableListStyle.Column} {
      :first-child {
        padding-right: 0;
      }

      :nth-child(2) {
        padding-left: 0;
      }
    }
  `;

  export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: absolute;
    bottom: 10px;
    right: 66px;
    ${NSDropdownStyle.DropdownItemsContainer} {
      padding: 8px 0;
    }
  `;

  export const JobDescriptionContainer = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin: 8px 0 32px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
    overflow-y: scroll;
    height: 400px;
    gap: 8px;
    width: 100%;
    padding-right: 100px;
    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutrals.grey03};
      border-radius: 20px;
    }
  `;

  export const NotActionStatus = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};

    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const InvalidDate = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  `;

  export const BatchActionBoldText = styled.div`
    display: inline-block;
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const SuspiciousActivityBoldText = styled.div`
    display: inline-block;
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    padding: 0 4px;
  `;

  export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 344px;
  `;

  export const ModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.error.red01};

    margin-top: 24px;
  `;

  export const ModalMessage = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.normal};
    color: ${(props) => props.theme.colors.dark.dark};

    margin-top: 8px;
  `;

  export const ModalButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 32px;

    ${NSMainButtonStyle.ButtonBase} {
      background-color: ${(props) => props.theme.colors.error.red01};
      &:hover {
        background-color: ${(props) => props.theme.colors.error.red03};
      }

      min-width: 198px;
    }
  `;

  export const ModalCloseButton = styled(MainButton)`
    background: none !important;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 16px 8px;
  `;

  export const CandidateStatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      path {
        stroke: ${(props) => props.theme.colors.neutrals.grey04};
      }
    }

    ${NSContextMenuStyle.ContextMenuButton} {
      display: flex;
    }

    ${NSContextMenuStyle.ContextMenuContent} {
      right: unset;
      left: 32px;
    }
  `;

  export const ScoresTooltipBase = styled.div`
    ${NSHistoryTooltipStyle.HistoryTooltipBase} {
      .react-tooltip {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 0;
      }
    }
  `;

  export const UpdateHistoryBase = styled.div`
    ${NSHistoryTooltipStyle.HistoryTooltipBase} {
      .react-tooltip {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 0;
      }
    }
  `;

  export const NoteTooltipBase = styled.div`
    cursor: pointer;

    ${NSHistoryTooltipStyle.HistoryTooltipBase} {
      .react-tooltip {
        max-height: 500px;
        overflow: auto;
        padding-bottom: 0;
      }
    }

    ${NSHistoryTooltipStyle.HistoryTooltipLabel} {
      color: ${(props) => props.theme.colors.warning.yellow03};
    }
  `;

  export const NoteTooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    ${NSNoteCardStyle.Container} {
      max-width: 250px;
      padding: 12px;
      color: ${(props) => props.theme.colors.dark.dark};
    }

    ${NSNoteCardStyle.Note} {
      font-size: ${(props) => props.theme.typography.preText.size};
      line-height: ${(props) => props.theme.typography.preText.lineHeight};
    }
  `;
}
