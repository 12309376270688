import {
  CV,
  DEFAULT_PAGINATION_OPTIONS,
  GET,
  PAGINATION_DEFAULT_LIMIT,
} from "../../../../utils/globalConstants";
import {
  ChangeCandidateStatusDocument,
  Client_Candidate_Project_Bool_Exp,
  Client_Candidate_Project_Candidate_List_Status_Enum,
  Client_Candidate_Project_Candidate_Resolution_Enum,
  DeleteClientCandidateProjectDocument,
  GetProjectEditInfoDocument,
  GetSignedUrlDocument,
  Order_By,
  SearchDocument,
  SendVideoInterviewLinkToCandidateDocument,
  UpdateCandidateResolutionDocument,
  Video_Interview_Question_Event_Type_Enum,
  Video_Interview_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../../../gql/graphql";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HistoryTooltip from "../../../../components/HistoryTooltip/HistoryTooltip";
import { ISolidButton } from "../../../../components/SolidButton/SolidButton.type";
import { NSCandidateListStyle } from "./Candidate-List.style";
import { NSCandidateListType } from "./Candidate-List.type";
import { NSContextMenuType } from "../../../../components/ContextMenu/ContextMenu.type";
import { NSTableListType } from "../../../../components/TableList/TableList.type";
import OtherProject from "../../../../components/OtherProject/OtherProject";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import StatusBadge from "../../../../components/StatusBadge/StatusBadge";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import UpdateHistory from "../../../../components/UpdateHistory/UpdateHistory";
import candidateStatusHelper from "../../../../utils/candidateStatusHelper";
import dayjs from "../../../../library/dayjs";
import fileTypeHelper from "../../../../utils/fileTypeHelper";
import i18n from "../../../../library/i18next";
import toast from "react-hot-toast";
import { useGetClientCandidateWithOrderAndFilter } from "../../../../service/ClientsCandidate/getClientCandidate";
import {
  useGraphQLMutation,
  useGraphQLQuery,
} from "../../../../hooks/useGraphQL";
import { useProjectDetailContext } from "../../../../context/ProjectDetailContext/ProjectDetailContext";
import { excelExporter } from "../../../../utils/excelExporter";
import { Trans } from "react-i18next";
import Checkbox from "../../../../components/Checkbox/CheckBox";
import { NSTableListStyle } from "../../../../components/TableList/TableList.style";
import ContextMenuHOC from "../../../../components/ContextMenuHOC/ContextMenuHOC";
import formatPhoneNumberHelper from "../../../../utils/formatPhoneNumberHelper";
import capitalizeFirstLetterHelper from "../../../../utils/capitalizeFirstLetterHelper";
import Icon from "../../../../components/Icon/Icon";
import NoteList from "../../../../components/NoteList/NoteList";
import icons from "../../../../assets/icons";

const useCandidateListVm = ({ type }: NSCandidateListType.ICandidateList) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const start = searchParams.get("start");
  const limit = searchParams.get("limit");
  const { projectId } = useParams();

  const path = {
    appliedlist: "applied",
    longlist: "long-list",
    shortlist: "short-list",
  };

  const startValue = parseInt(start!) || 0;
  const limitValue = parseInt(limit!) || PAGINATION_DEFAULT_LIMIT;
  const isValidLimit = DEFAULT_PAGINATION_OPTIONS.some(
    (option) => parseInt(option.label) === limitValue
  );

  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({
    start: startValue,
    limit: isValidLimit ? limitValue : PAGINATION_DEFAULT_LIMIT,
  });
  const [modalContent, setModalContent] =
    useState<NSCandidateListType.IModalContent | null>(null);
  const [isAllTableRowsSelected, setAllTableRowsSelected] =
    useState<boolean>(false);
  const [selectedTableRows, setSelectedTableRows] = useState<
    { id: string; checked: boolean }[]
  >([]);

  const onClickModalCloseButton = () => {
    setModalContent(null);
  };

  const handleOpenModal: (
    title: string,
    message: ReactNode,
    actionButtonLabel: string,
    closeButtonLabel: string,
    actionButtonHandler: () => void
  ) => void = (
    title,
    message,
    actionButtonLabel,
    closeButtonLabel,
    actionButtonHandler
  ) => {
    setModalContent({
      title,
      message,
      actionButtonLabel,
      closeButtonLabel,
      actionButtonHandler,
    });
  };

  const handleSelectAllTableRows = () => {
    if (selectedTableRows.filter((item) => item.checked).length === 0) {
      const updatedSelectedItems = generalCandidateData.map((item) => ({
        id: item.id,
        checked: true,
      }));
      setSelectedTableRows(updatedSelectedItems);
      setAllTableRowsSelected(true);
    } else {
      const updatedSelectedItems = selectedTableRows.map((item) => ({
        ...item,
        checked: false,
      }));
      setSelectedTableRows(updatedSelectedItems);
      setAllTableRowsSelected(false);
    }
  };

  const handleSelectTableRow = (itemId: string) => {
    const itemIndex = selectedTableRows.findIndex((item) => item.id === itemId);
    if (itemIndex !== -1) {
      const updatedSelectedItems = [...selectedTableRows];
      updatedSelectedItems[itemIndex] = {
        ...updatedSelectedItems[itemIndex],
        checked: !updatedSelectedItems[itemIndex].checked,
      };
      setSelectedTableRows(updatedSelectedItems);
      if (
        !updatedSelectedItems[itemIndex].checked &&
        selectedTableRows.filter((item) => item.checked).length === 1
      ) {
        setAllTableRowsSelected(false);
        return;
      }
    } else {
      setSelectedTableRows((prevState) => [
        ...prevState,
        { id: itemId, checked: true },
      ]);
    }
    setAllTableRowsSelected(true);
  };

  const checkSelectedTableRowsBatchActionStatus = (
    type: Video_Interview_Type_Enum
  ) => {
    const selectedIds = new Set();
    selectedTableRows.forEach((row) => {
      if (row.checked) {
        selectedIds.add(row.id);
      }
    });

    const filteredStatuses = candidateInformationData
      ?.filter((candidate) => selectedIds.has(candidate.id))
      .map((candidate) =>
        type === Video_Interview_Type_Enum.CompetencyAssessment
          ? candidate.competencyLastStatus
          : candidate.englishTestLastStatus
      );

    const hasAnyRowSentInterview = filteredStatuses?.some(
      (status) =>
        status === Video_Interview_Status_Enum.InReview ||
        status === Video_Interview_Status_Enum.VideoSent ||
        status === Video_Interview_Status_Enum.VideoReceived ||
        status === Video_Interview_Status_Enum.Scored
    );

    return !hasAnyRowSentInterview;
  };

  const checkSelectedTableRowsAllScored = () => {
    const selectedIds = new Set();
    selectedTableRows.forEach((row) => {
      if (row.checked) {
        selectedIds.add(row.id);
      }
    });

    const filteredCompetencyStatuses = candidateInformationData
      ?.filter((candidate) => selectedIds.has(candidate.id))
      .map((candidate) => candidate.competencyLastStatus);

    const filteredEnglishStatuses = candidateInformationData
      ?.filter((candidate) => selectedIds.has(candidate.id))
      .map((candidate) => candidate.englishTestLastStatus);

    const isAllCompetenciesScored = filteredCompetencyStatuses?.every(
      (status) => status === Video_Interview_Status_Enum.Scored
    );

    const isAllEnglishScored = filteredEnglishStatuses?.every(
      (status) => status === Video_Interview_Status_Enum.Scored
    );

    return isAllCompetenciesScored || isAllEnglishScored;
  };

  const updateCheckbox = (itemId: string): boolean => {
    const selectedItem = selectedTableRows.find((item) => item.id === itemId);
    return selectedItem ? selectedItem.checked : false;
  };

  const clearCheckBoxStates = () => {
    setAllTableRowsSelected(false);
    setSelectedTableRows([]);
  };

  const handleSetPagination = useCallback((start: number, limit: number) => {
    setPagination({ start, limit });

    navigate(
      `/projects/detail/${projectId}/${
        path[type as NSCandidateListType.ICandidateList["type"]]
      }?start=${start}&limit=${limit}`,
      {
        state: { projectId },
      }
    );
  }, []);

  const {
    checkedVideoStatusItems,
    searchString,
    listOrders,
    selectedOrder,
    filterConditionsByOrder,
    setCheckedVideoStatusItems,
    setSelectedOrder,
    onCheckedItems,
  } = useProjectDetailContext();

  useEffect(() => {
    setSelectedOrder(1);
    setCheckedVideoStatusItems([]);
    onCheckedItems([]);
  }, [type]);

  const constTypeToEnum = {
    longlist: Client_Candidate_Project_Candidate_List_Status_Enum.InLonglist,
    shortlist: Client_Candidate_Project_Candidate_List_Status_Enum.InShortlist,
    appliedlist: Client_Candidate_Project_Candidate_List_Status_Enum.Applied,
  };

  const { data: searchData } = useGraphQLQuery(
    SearchDocument,
    {
      enabled: !!searchString,
    },
    {
      index: "client_candidate",
      query: searchString ?? "",
      limit: pagination.limit,
      offset: pagination.start,
    }
  );

  const clientCandidateIds =
    searchData?.search?.clientCandidate?.map((item) => item?.id) || [];

  const listFilter = useMemo(
    () => ({
      _and: [
        { project_id: { _eq: projectId } },
        !!searchString
          ? {
              client_candidate_id: { _in: clientCandidateIds },
            }
          : {},
        {
          candidate_list_status: {
            _eq: constTypeToEnum[type],
          },
        },
        filterConditionsByOrder[selectedOrder as number] || {},
        checkedVideoStatusItems && checkedVideoStatusItems.length > 0
          ? {
              _or: checkedVideoStatusItems as Client_Candidate_Project_Bool_Exp[],
            }
          : {},
      ],
    }),
    [
      checkedVideoStatusItems,
      projectId,
      type,
      clientCandidateIds,
      searchString,
      selectedOrder,
    ]
  );

  const { data: projectData } = useGraphQLQuery(
    GetProjectEditInfoDocument,
    undefined,
    {
      projectId,
    }
  );

  const {
    data: clientCandidate,
    refetch,
    rest,
  } = useGetClientCandidateWithOrderAndFilter(
    pagination.limit,
    pagination.start,
    projectId!,
    constTypeToEnum[type],
    listFilter,
    listOrders && selectedOrder
      ? listOrders[selectedOrder]
      : { client_candidate: { name: Order_By.Asc } },
    {
      enabled:
        searchString && !searchData?.search?.clientCandidate?.length!!
          ? false
          : undefined,
    }
  );

  const { refetch: getAllCandidatesForExcel } =
    useGetClientCandidateWithOrderAndFilter(
      99999,
      0,
      projectId!,
      constTypeToEnum[type],
      listFilter,
      listOrders && selectedOrder
        ? listOrders[selectedOrder]
        : { client_candidate: { name: Order_By.Asc } },
      {
        enabled: false,
      }
    );

  const projectsInterviewTemplateId =
    projectData?.project_by_pk?.interview_template?.id;

  const clientId = useMemo(
    () => projectData?.project_by_pk?.client.id,
    [projectData]
  );

  const { mutateAsync: getSignedUrl } =
    useGraphQLMutation(GetSignedUrlDocument);

  const { mutateAsync: changeStatus } = useGraphQLMutation(
    ChangeCandidateStatusDocument
  );

  const { mutateAsync: changeResolution } = useGraphQLMutation(
    UpdateCandidateResolutionDocument
  );

  const { mutateAsync: deleteClientCandidate } = useGraphQLMutation(
    DeleteClientCandidateProjectDocument
  );

  const handleDownload = useCallback((url: string, name: string) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        const linkkk = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = linkkk;
        a.download = name;
        a.click();
      });
    });
  }, []);

  const handleDownloadCVButton = useCallback(
    async (id: string) => {
      let fileUrlData: any;
      const fileName = clientCandidate?.client_candidate_project.find(
        (item) => item.client_candidate_id === id
      )?.client_candidate.cv_file_name;
      if (fileName) {
        const type = fileTypeHelper(fileName);
        fileUrlData = await getSignedUrl({
          signedUrlInput: {
            contentType: type.type!,
            filenames: [fileName],
            folder: CV,
            operation: GET,
          },
        });

        handleDownload(
          fileUrlData?.getSignedUrl?.signedUrls[0] as string,
          fileName
        );
      } else {
        toast(
          <ToastAlert
            description={i18n.t("projects.noCvError")}
            type="error"
          />,

          {
            id: "cvDownloadError",
          }
        );
      }
    },

    [clientCandidate, getSignedUrl, handleDownload]
  );

  const handleDownloadListButton = async () => {
    const response = await getAllCandidatesForExcel();

    const allCandidatesInformationDataForExcel =
      response.data?.client_candidate_project?.map((clientCandidateItem) => {
        const competencyInterview = clientCandidateItem.video_interviews
          ?.map(
            (i) =>
              i.video_interview_statuses?.filter(
                (interview) =>
                  interview.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.CompetencyAssessment
              )[0]
          )
          .filter((status) => status !== undefined)[0];

        const englishTest = clientCandidateItem.video_interviews
          .map(
            (i) =>
              i.video_interview_statuses.filter(
                (interview) =>
                  interview.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.EnglishAssessment
              )[0]
          )
          .filter((status) => status !== undefined)[0];

        return {
          name: clientCandidateItem.client_candidate.name,
          surname: clientCandidateItem.client_candidate.surname,
          email: clientCandidateItem.client_candidate.email_address,
          mobileNumber: clientCandidateItem.client_candidate.mobile_number,
          competencyLastStatus:
            competencyInterview?.status ??
            (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
          competencyLastScore: competencyInterview?.video_interview.score ?? 0,
          englishTestLastStatus:
            englishTest?.status ??
            (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
          englishTestLastScore: englishTest?.video_interview.score ?? 0,
          candidateStatus: clientCandidateItem.candidate_resolution,
          score:
            clientCandidateItem.video_interviews[0]?.video_interview_statuses[0]
              ?.video_interview.score ?? 0,
          updateHistory:
            clientCandidateItem.video_interviews[0]?.video_interview_statuses?.map(
              (updateHistoryItem) => ({
                status:
                  updateHistoryItem?.status ??
                  (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
                score: updateHistoryItem?.video_interview.score ?? 0,
                id: updateHistoryItem?.id,
                createdAt: updateHistoryItem?.created_at ?? "",
              })
            ) ?? [],
        };
      });

    const dataForExcel = allCandidatesInformationDataForExcel!?.map((item) => {
      const competencyInterviewStatus =
        item.competencyLastStatus === Video_Interview_Status_Enum.Scored
          ? item.competencyLastScore
          : candidateStatusHelper(
              item.competencyLastStatus as string,
              item.competencyLastScore.toString()
            ).label;

      const englishTestStatus =
        item.englishTestLastStatus === Video_Interview_Status_Enum.Scored
          ? item.englishTestLastScore
          : candidateStatusHelper(
              item.englishTestLastStatus as string,
              item.englishTestLastScore.toString()
            ).label;

      const excelData = {
        [i18n.t("projects.candidateName")]: `${item.name} ${item.surname}`,
        [i18n.t("projects.emailAddress")]: item.email,
        [i18n.t("projects.phoneNumber")]: item.mobileNumber,
        [i18n.t("projects.competencyInterviewStatus")]:
          competencyInterviewStatus,
        [i18n.t("projects.englishTestStatus")]: englishTestStatus,
      };

      if (type === "shortlist") {
        Object.assign(excelData, {
          [i18n.t("projects.score")]: String(item.score),
          [i18n.t("projects.candidateStatus")]: candidateStatusHelper(
            item.candidateStatus ??
              Client_Candidate_Project_Candidate_Resolution_Enum.NoShow
          ).label,
        });
      } else {
        Object.assign(excelData, {
          [i18n.t("projects.lastUpdateDate")]:
            item.updateHistory.length > 0
              ? dayjs(item?.updateHistory[0]?.createdAt ?? "").format(
                  "DD.MM.YYYY[,] hh:mm"
                )
              : null,
        });
      }

      return excelData;
    });
    const projectName = projectData?.project_by_pk?.project_name;
    const date = dayjs().format("DD.MM.YYYY[,] HH:mm");

    excelExporter(dataForExcel, `hc-${projectName}-export-${date}.xlsx`);
  };

  const handleGoToScorePage = useCallback(
    async (
      id: string,
      interviewId: string,
      baseType: keyof typeof NSCandidateListType.ScorePageBaseType
    ) => {
      const interview = clientCandidate?.client_candidate_project
        .find((item) => item.client_candidate_id === id)
        ?.video_interviews.find((item) => item.id === interviewId);
      if (
        interview?.video_interview_statuses[0].status ===
        Video_Interview_Status_Enum.Scored
      ) {
        navigate(
          `/projects/detail/${projectId}/candidate/candidate-score-result/${interviewId}/${baseType}`,
          {
            state: { projectId, interviewId },
          }
        );
      } else {
        toast(
          <ToastAlert
            description={i18n.t("projects.notReviewedError")}
            type="error"
          />,

          {
            id: "interviewError",
          }
        );
      }
    },
    [navigate, projectId, clientCandidate]
  );

  const handleOpenVideoPreview = useCallback(
    async (id: string, interviewId: string) => {
      const interview = clientCandidate?.client_candidate_project
        .find((item) => item.client_candidate_id === id)
        ?.video_interviews.find((item) => item.id === interviewId);
      if (
        interview?.video_interview_statuses[0].status ===
          Video_Interview_Status_Enum.Scored ||
        interview?.video_interview_statuses[0].status ===
          Video_Interview_Status_Enum.VideoSent ||
        interview?.video_interview_statuses[0].status ===
          Video_Interview_Status_Enum.VideoReceived
      ) {
        navigate(`/projects/detail/${projectId}/video-preview/${interviewId}`, {
          state: { projectId, interviewId },
        });
      } else {
        toast(
          <ToastAlert
            description={i18n.t("projects.videoNotSentError")}
            type="error"
          />,

          {
            id: "video ",
          }
        );
      }
    },
    [navigate, projectId, clientCandidate]
  );

  const changeCandidateStatusMessages = {
    APPLIED: {
      success: i18n.t("projects.appliedListSuccess"),
      error: i18n.t("projects.sendingCandidateError"),
    },
    IN_LONGLIST: {
      success: i18n.t("projects.longListSuccess"),
      error: i18n.t("projects.sendingCandidateError"),
    },
    IN_SHORTLIST: {
      success: i18n.t("projects.sendShortListSuccess"),
      error: i18n.t("projects.sendingCandidateError"),
    },
    POOL: {
      success: i18n.t("projects.sendPoolSuccess"),
      error: i18n.t("projects.sendingCandidateError"),
    },
  };

  const handleChangeCandidateStatus = useCallback(
    async (
      ids: number[],
      status: Client_Candidate_Project_Candidate_List_Status_Enum
    ) => {
      changeStatus({
        ids: ids,
        status,
      })
        .then(async () => {
          toast(
            <ToastAlert
              description={changeCandidateStatusMessages[status].success}
              type="success"
            />,

            {
              id: "sendClientSuccess",
            }
          );
          await refetch();
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={changeCandidateStatusMessages[status].error}
              type="error"
            />,
            {
              id: "sendCandidateError",
            }
          );
        });
    },

    [changeStatus, clientCandidate]
  );

  const handleDeleteCandidate = useCallback(
    async (ids: string[]) => {
      deleteClientCandidate({
        projectId,
        clientCandidateIds: ids,
      })
        .then(async (response) => {
          const deleteCandidateResponse =
            response as unknown as NSCandidateListType.DeleteCandidateResponse;
          if (
            deleteCandidateResponse?.delete_client_candidate_project?.returning
              ?.length > 0
          ) {
            toast(
              <ToastAlert
                description={i18n.t("projects.deleteCandidateSuccess")}
                type="success"
              />,

              {
                id: "deleteCandidateSuccess",
              }
            );
            await refetch();
          } else {
            throw Error();
          }
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.deleteCandidateError")}
              type="error"
            />,
            {
              id: "deleteCandidateError",
            }
          );
        });
    },
    [deleteClientCandidate, projectId, clientCandidate]
  );

  const candidateInformationData = useMemo(
    () =>
      clientCandidate?.client_candidate_project.map((clientCandidateItem) => {
        const competencyInterviewStatus = clientCandidateItem.video_interviews
          ?.map(
            (i) =>
              i.video_interview_statuses?.filter(
                (interview) =>
                  interview.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.CompetencyAssessment
              )[0]?.status
          )
          .filter((status) => status !== undefined)[0];

        const englishTestStatus = clientCandidateItem.video_interviews
          .map(
            (i) =>
              i.video_interview_statuses.filter(
                (interview) =>
                  interview.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.EnglishAssessment
              )[0]?.status
          )
          .filter((status) => status !== undefined)[0];

        const scoredStatuses = clientCandidateItem.video_interviews.flatMap(
          (item) => {
            const groupedByType: Record<string, any> = {};

            item.video_interview_statuses
              .filter(
                (status) => status.status === Video_Interview_Status_Enum.Scored
              )
              .forEach((status) => {
                const type = status.video_interview.video_interview_type;
                if (!groupedByType[type]) {
                  groupedByType[type] = status;
                }
              });

            return Object.values(groupedByType);
          }
        );

        const getInterviewIdByType = (
          interviewType: Video_Interview_Type_Enum
        ) => {
          for (const interview of clientCandidateItem.video_interviews) {
            for (const status of interview.video_interview_statuses) {
              if (
                status.video_interview.video_interview_type === interviewType
              ) {
                return interview.id;
              }
            }
          }
          return null;
        };

        const getInterviewTypeSummary = (
          interviewType: Video_Interview_Type_Enum
        ) => {
          let questionOrders: number[] = [];
          let totalEventCount = 0;

          clientCandidateItem.video_interviews.forEach((interview) => {
            if (interview.video_interview_type === interviewType) {
              interview.video_interview_questions.forEach((question) => {
                question.video_interview_question_events.forEach((event) => {
                  if (
                    event.type ===
                      Video_Interview_Question_Event_Type_Enum.VisibilityHidden ||
                    event.type ===
                      Video_Interview_Question_Event_Type_Enum.WindowBlurred ||
                    event.type ===
                      Video_Interview_Question_Event_Type_Enum.WindowResized
                  ) {
                    const questionOrder =
                      event.video_interview_question.review_metric_question
                        .interview_template_review_metric_questions[0]
                        ?.question_order;
                    if (questionOrder !== undefined) {
                      questionOrders.push(questionOrder);
                    }
                    totalEventCount++;
                  }
                });
              });
            }
          });

          return {
            questionOrders,
            totalEventCount,
          };
        };

        const competencySuspiciousActivity = getInterviewTypeSummary(
          Video_Interview_Type_Enum.CompetencyAssessment
        );
        const englishAssessmentSuspiciousActivity = getInterviewTypeSummary(
          Video_Interview_Type_Enum.EnglishAssessment
        );

        return {
          id: clientCandidateItem.client_candidate.id ?? "1",
          candidateProjectId: clientCandidateItem.id,
          name: clientCandidateItem.client_candidate.name,
          surname: clientCandidateItem.client_candidate.surname,
          email: clientCandidateItem.client_candidate.email_address,
          mobileNumber: clientCandidateItem.client_candidate.mobile_number,
          candidateResolution: clientCandidateItem.candidate_resolution,
          scoredStatuses,
          competencyLastStatus:
            competencyInterviewStatus ??
            (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
          englishTestLastStatus:
            englishTestStatus ??
            (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
          status:
            clientCandidateItem.video_interviews[0]?.video_interview_statuses[0]
              ?.status ??
            (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
          lastStatusInterviewType:
            clientCandidateItem.video_interviews[0]?.video_interview_statuses[0]
              ?.video_interview?.video_interview_type ??
            (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
          score:
            clientCandidateItem.video_interviews[0]?.video_interview_statuses[0]
              ?.video_interview.score ?? 0,
          interviewId: getInterviewIdByType(
            Video_Interview_Type_Enum.CompetencyAssessment
          ),
          englishTestId: getInterviewIdByType(
            Video_Interview_Type_Enum.EnglishAssessment
          ),
          ccpId: clientCandidateItem.id,
          otherProjects:
            clientCandidateItem.client_candidate.client_candidate_projects
              .filter((i) => i.project.id !== projectId)
              .map((otherProjectsItem) => ({
                status:
                  otherProjectsItem?.video_interviews[0]
                    ?.video_interview_statuses?.[0]?.status ??
                  (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
                score:
                  otherProjectsItem?.video_interviews[0]
                    ?.video_interview_statuses?.[0]?.video_interview.score ?? 0,
                projectName: otherProjectsItem.project.project_name,
                projectId: otherProjectsItem.project.id as string,
              })) ?? [],
          scoreHistory: scoredStatuses?.map((item) => ({
            score: item?.video_interview.score ?? 0,
            status: item?.status,
            projectId: String(item?.id),
            projectName:
              item.video_interview.video_interview_type ===
              Video_Interview_Type_Enum.CompetencyAssessment
                ? i18n.t("projects.competencyInterview")
                : i18n.t("projects.englishTest"),
          })),
          updateHistory:
            clientCandidateItem.video_interviews?.flatMap(
              (videoInterview) =>
                videoInterview.video_interview_statuses?.map(
                  (updateHistoryItem) => ({
                    status:
                      updateHistoryItem?.status ??
                      (Video_Interview_Status_Enum.NotSet as Video_Interview_Status_Enum),
                    score: updateHistoryItem?.video_interview.score ?? 0,
                    id: updateHistoryItem?.id,
                    createdAt: updateHistoryItem?.created_at ?? "",
                    interviewType:
                      updateHistoryItem?.video_interview.video_interview_type,
                  })
                ) ?? []
            ) ?? [],
          notes: clientCandidateItem.client_candidate_project_notes,
          competencySuspiciousActivity,
          englishAssessmentSuspiciousActivity,
        };
      }),
    [clientCandidate]
  );

  const handleOpenEditDrawer = useCallback(
    async (id: string) => {
      const candidateId = clientCandidate?.client_candidate_project.find(
        (item) => item.client_candidate_id === id
      )?.client_candidate_id;

      navigate(`/projects/detail/${projectId}/edit-candidate/${candidateId}`, {
        state: { projectId, candidateId },
      });
    },

    [clientCandidate?.client_candidate_project, navigate, projectId]
  );

  const handleOpenNotesDrawer = useCallback(
    async (id: string) => {
      const candidateId = clientCandidate?.client_candidate_project.find(
        (item) => item.client_candidate_id === id
      )?.client_candidate_id;

      const candidateProjectId = clientCandidate?.client_candidate_project.find(
        (item) => item.client_candidate_id === id
      )?.id;

      navigate(
        `/projects/detail/${projectId}/notes/${candidateId}/${candidateProjectId}/${type}/add-note?start=${start}&limit=${limit}`
      );
    },

    [clientCandidate?.client_candidate_project, navigate, projectId]
  );

  const handleClickLongListTableButton = useCallback(
    (_ccpId: number[], interviewId: string) => {
      navigate(
        `/projects/detail/${projectId}/candidate/candidate-video-preview/${interviewId}`,
        {
          state: { projectId, interviewId },
        }
      );
    },
    [navigate, projectId]
  );
  const { mutateAsync: sendVideoInterviewLink } = useGraphQLMutation(
    SendVideoInterviewLinkToCandidateDocument
  );

  const handleClickGenerateAndSendLinkButton = useCallback(
    (
      ccpIds: number[],
      type?: Video_Interview_Type_Enum,
      resetInterview?: boolean
    ) => {
      sendVideoInterviewLink({
        ccpIds,
        interviewType: type ?? Video_Interview_Type_Enum.CompetencyAssessment,
        resetInterview: resetInterview,
      })
        .then(async () => {
          toast(
            <ToastAlert
              description={i18n.t("projects.linkGenerateAndSentSuccess")}
              type="success"
            />,

            {
              id: "linkGenerateAndSendSuccess",
            }
          );

          await refetch();
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.linkGenerateAndSentError")}
              type="error"
            />,

            {
              id: "linkGenerateAndSendError",
            }
          );
        });
    },
    [sendVideoInterviewLink]
  );

  const generateLinkAndSendButton: ISolidButton = useMemo(
    () => ({
      label: i18n.t("projects.generateLinkAndSend"),
      variant: "label-w-icon-prefix",
      prefixIcon: "Link",
    }),
    []
  );

  const startScoringButton: ISolidButton = useMemo(
    () => ({
      label: i18n.t("projects.startScoring"),
      variant: "label-w-icon-prefix",
      prefixIcon: "Star",
    }),
    []
  );

  const rescoringButton: ISolidButton = useMemo(
    () => ({
      label: i18n.t("projects.rescore"),
      variant: "label-w-icon-prefix",
      prefixIcon: "Rescore",
    }),
    []
  );

  const shownButtonByStatus = useMemo(
    () => ({
      CANDIDATE_IN_PROGRESS: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      CANDIDATE_NOT_INTERESTED: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      NO_AI_REVIEW_WANTED: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      EXPIRED: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      INAPPROPRIATE_CONTENT: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      INSUFFICIENT_CONTENT: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      IN_REVIEW: {
        button: startScoringButton,
        action: handleClickLongListTableButton,
      },
      LINK_SENT: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      NOT_SET: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      NOT_COMPLETED: {
        button: generateLinkAndSendButton,
        action: handleClickGenerateAndSendLinkButton,
      },
      VIDEO_SENT: {
        button: startScoringButton,
        action: handleClickLongListTableButton,
      },
      VIDEO_RECEIVED: {
        button: startScoringButton,
        action: handleClickLongListTableButton,
      },
      SCORED: {
        button: rescoringButton,
        action: handleClickLongListTableButton,
      },
    }),
    [
      generateLinkAndSendButton,
      handleClickGenerateAndSendLinkButton,
      handleClickLongListTableButton,
      rescoringButton,
      startScoringButton,
    ]
  );

  const handleClickAddCandidate = useCallback(() => {
    navigate(`/projects/detail/${projectId}/add-candidate`, {
      state: {
        projectId,
        clientId,
      },
    });
  }, [clientId, navigate, projectId]);

  const handleClickAddCandidateFromExcel = useCallback(() => {
    navigate(`/projects/detail/${projectId}/add-candidate-from-excel`, {
      state: {
        projectId,
        clientId,
      },
    });
  }, [clientId, navigate, projectId]);

  const isLoading = useMemo(() => rest.isLoading, [rest.isLoading]);

  const shortlistContextMenuItems = [
    i18n.t("projects.sendToLonglist"),
    i18n.t("projects.addNewNoteTitle"),
    i18n.t("projects.englishTestDetail"),
    i18n.t("projects.competencyInterviewDetail"),
    i18n.t("projects.downloadCv"),
    i18n.t("projects.deleteCandidate"),
  ];

  const longlistContextMenuItems = [
    i18n.t("projects.sendToShortlist"),
    i18n.t("projects.sendToApplied"),
    i18n.t("projects.englishTestDetail"),
    i18n.t("projects.competencyInterviewDetail"),
    i18n.t("projects.editCandidateInfo"),
    i18n.t("projects.addNewNoteTitle"),
    i18n.t("projects.downloadCv"),
    i18n.t("projects.deleteCandidate"),
  ];

  const appliedlistContextMenuItems = [
    i18n.t("projects.sendToLonglist"),
    i18n.t("projects.addNewNoteTitle"),
    i18n.t("projects.downloadCv"),
    i18n.t("projects.deleteCandidate"),
  ];

  const generalTableContextMenu: NSContextMenuType.IContextMenuItem[] = useMemo(
    () => [
      {
        isSeperatedFromBottom: true,
        iconName: "SendAppliedlist",
        label: i18n.t("projects.sendToApplied"),
        onClick: (_index, id) => {
          const candidateProjectId = candidateInformationData?.find(
            (item) => item.id === id
          )?.candidateProjectId;
          handleChangeCandidateStatus(
            [candidateProjectId] as number[],
            Client_Candidate_Project_Candidate_List_Status_Enum.Applied
          );
        },
      },
      {
        isSeperatedFromBottom: true,
        iconName: type === "appliedlist" ? "SendShortlist" : "SendLonglist",
        label: i18n.t("projects.sendToLonglist"),
        onClick: (_index, id) => {
          const candidateProjectId = candidateInformationData?.find(
            (item) => item.id === id
          )?.candidateProjectId;
          handleChangeCandidateStatus(
            [candidateProjectId] as number[],
            Client_Candidate_Project_Candidate_List_Status_Enum.InLonglist
          );
        },
      },
      {
        iconName: "InterviewDetail",
        label: i18n.t("projects.competencyInterviewDetail"),
        cascadingItem: [
          {
            iconName: "Star",
            label: i18n.t("projects.candidateReport"),
            onClick: (_index: number, id: string) => {
              const interviewId = candidateInformationData?.find(
                (item) => item.id === id
              )?.interviewId;
              handleGoToScorePage(
                id as string,
                interviewId,
                NSCandidateListType.ScorePageBaseType
                  .QUESTION as keyof typeof NSCandidateListType.ScorePageBaseType
              );
            },
          },
          /* {
            iconName: "Video",
            label: i18n.t("projects.viewVideo"),
            onClick: (_index: number, id: string) => {
              const interviewId = candidateInformationData?.find(
                (item) => item.id === id
              )?.interviewId;
              handleOpenVideoPreview(id as string, interviewId);
            },
          }, */
        ],
      },
      {
        iconName: "TestDetail",
        label: i18n.t("projects.englishTestDetail"),
        cascadingItem: [
          {
            iconName: "Star",
            label: i18n.t("projects.candidateReport"),
            onClick: (_index: number, id: string) => {
              const testId = candidateInformationData?.find(
                (item) => item.id === id
              )?.englishTestId;
              handleGoToScorePage(
                id as string,
                testId,
                NSCandidateListType.ScorePageBaseType
                  .INTERVIEW as keyof typeof NSCandidateListType.ScorePageBaseType
              );
            },
          },
          {
            iconName: "Video",
            label: i18n.t("projects.viewVideo"),
            onClick: (_index: number, id: string) => {
              const testId = candidateInformationData?.find(
                (item) => item.id === id
              )?.englishTestId;
              handleOpenVideoPreview(id as string, testId);
            },
          },
        ],
      },

      {
        iconName: "EditCandidateInfo",
        label: i18n.t("projects.editCandidateInfo"),
        onClick: (_index, id) => handleOpenEditDrawer(id as string),
      },
      {
        iconName: "Note",
        label: i18n.t("projects.addNewNoteTitle"),
        onClick: (_index, id) => handleOpenNotesDrawer(id as string),
      },
      {
        iconName: "Download",
        label: i18n.t("projects.downloadCv"),
        onClick: (_index, id) => handleDownloadCVButton(id as string),
      },
      {
        isSeperatedFromTop: true,
        iconName: "Trash",
        label: i18n.t("projects.deleteCandidate"),
        onClick: (_index, id) => {
          handleOpenModal(
            i18n.t("candidate.batchActionDeleteTitle"),
            <Trans
              i18nKey={i18n.t("candidate.candidateDeleteMessage")}
              components={{
                bold: <NSCandidateListStyle.BatchActionBoldText />,
              }}
            />,
            i18n.t("general.delete"),
            i18n.t("general.giveUp"),
            () => handleDeleteCandidate([id] as string[])
          );
        },
      },
    ],
    [
      type,
      clientCandidate,
      handleChangeCandidateStatus,
      handleDeleteCandidate,
      handleDownloadCVButton,
      handleGoToScorePage,
      handleOpenEditDrawer,
      handleOpenVideoPreview,
    ]
  );

  const shortlistContextMenu = useMemo(
    () =>
      generalTableContextMenu.filter((i) =>
        shortlistContextMenuItems.includes(i.label!)
      ),

    [generalTableContextMenu, shortlistContextMenuItems]
  );

  const longlistContextMenu = useMemo(
    () =>
      generalTableContextMenu.filter((i) =>
        longlistContextMenuItems.includes(i.label!)
      ),

    [generalTableContextMenu, longlistContextMenuItems]
  );

  const appliedlistContextMenu = useMemo(
    () =>
      generalTableContextMenu.filter((i) =>
        appliedlistContextMenuItems.includes(i.label!)
      ),

    [generalTableContextMenu, appliedlistContextMenuItems]
  );

  const tableContextMenu = {
    appliedlist: appliedlistContextMenu,
    longlist: longlistContextMenu,
    shortlist: shortlistContextMenu,
  };

  const handleChangeCandidateResolution = (
    id: number,
    resolution: Client_Candidate_Project_Candidate_Resolution_Enum
  ) => {
    changeResolution({
      id,
      candidate_resolution: resolution,
    })
      .then(async () => {
        toast(
          <ToastAlert
            description={i18n.t("projects.updateResolutionSuccess")}
            type="success"
          />,

          {
            id: "updateResolutionSuccess",
          }
        );
        await refetch();
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.updateResolutionError")}
            type="error"
          />,
          {
            id: "updateResolutionError",
          }
        );
      });
  };

  const candidateResolutionContextMenu: NSContextMenuType.IContextMenuItem[] = [
    {
      label: i18n.t("projects.noShow"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.NoShow
        ),
    },
    {
      label: i18n.t("projects.candidateWithdrew"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.CandidateWithdrew
        ),
    },
    {
      label: i18n.t("projects.doesNotMeetCriteria"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.DoesNotMeetCriteria
        ),
    },
    {
      label: i18n.t("projects.hired"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.Hired
        ),
    },
    {
      label: i18n.t("projects.interviewSuccessful"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.InterviewSuccessful
        ),
    },
    {
      label: i18n.t("projects.interviewUnsuccessful"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.InterviewUnsuccessful
        ),
    },
    {
      label: i18n.t("projects.offerDeclined"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.OfferDeclined
        ),
    },
    {
      label: i18n.t("projects.offerMade"),
      onClick: (_index, id) =>
        handleChangeCandidateResolution(
          Number(id),
          Client_Candidate_Project_Candidate_Resolution_Enum.OfferMade
        ),
    },
  ];

  const generalBatchActionContextMenu: NSContextMenuType.IContextMenuItem[] =
    useMemo(() => {
      const selectedIds = selectedTableRows
        .filter((row) => row.checked)
        .map((row) => row.id);

      const selectedCandidateProjectIds = candidateInformationData
        ?.filter((item) => selectedIds.includes(item.id))
        .map((item) => item.candidateProjectId);
      return [
        {
          iconName: "SendAppliedlist",
          label: i18n.t("projects.sendToApplied"),
          onClick: () =>
            handleOpenModal(
              i18n.t("candidate.batchActionAppliedlistTitle"),
              <Trans
                i18nKey={i18n.t("candidate.batchActionAppliedlistMessage")}
                values={{
                  selectedRowsCount: selectedTableRowsCount,
                }}
                components={{
                  bold: <NSCandidateListStyle.BatchActionBoldText />,
                }}
              />,
              i18n.t("general.send"),
              i18n.t("general.giveUp"),
              () =>
                handleChangeCandidateStatus(
                  selectedCandidateProjectIds as number[],
                  Client_Candidate_Project_Candidate_List_Status_Enum.Applied
                )
            ),
        },
        {
          iconName: type === "appliedlist" ? "SendShortlist" : "SendLonglist",
          label: i18n.t("projects.sendToLonglist"),
          onClick: () =>
            handleOpenModal(
              i18n.t("candidate.batchActionLonglistTitle"),
              <Trans
                i18nKey={i18n.t("candidate.batchActionLonglistMessage")}
                values={{
                  selectedRowsCount: selectedTableRowsCount,
                }}
                components={{
                  bold: <NSCandidateListStyle.BatchActionBoldText />,
                }}
              />,
              i18n.t("general.send"),
              i18n.t("general.giveUp"),
              () =>
                handleChangeCandidateStatus(
                  selectedCandidateProjectIds as number[],
                  Client_Candidate_Project_Candidate_List_Status_Enum.InLonglist
                )
            ),
        },
        {
          isSeperatedFromTop: true,
          iconName: "Trash",
          label: i18n.t("projects.deleteCandidate"),
          onClick: () =>
            handleOpenModal(
              i18n.t("candidate.batchActionDeleteTitle"),
              <Trans
                i18nKey={i18n.t("candidate.batchActionDeleteMessage")}
                values={{
                  selectedRowsCount: selectedTableRowsCount,
                }}
                components={{
                  bold: <NSCandidateListStyle.BatchActionBoldText />,
                }}
              />,
              i18n.t("general.delete"),
              i18n.t("general.giveUp"),
              () => handleDeleteCandidate(selectedIds)
            ),
        },
      ];
    }, [clientCandidate, isAllTableRowsSelected, selectedTableRows]);

  const shortlistBatchActionContextMenuItems = [
    i18n.t("projects.sendToLonglist"),
    i18n.t("projects.deleteCandidate"),
  ];

  const longlistBatchActionContextMenuItems = [
    i18n.t("projects.inviteToTestInterview"),
    i18n.t("projects.generateLinkAndSend"),
    i18n.t("projects.sendToShortlist"),
    i18n.t("projects.sendToApplied"),
    i18n.t("projects.deleteCandidate"),
  ];

  const appliedlistBatchActionContextMenuItems = [
    i18n.t("projects.sendToLonglist"),
    i18n.t("projects.deleteCandidate"),
  ];

  const shortlistBatchActionContextMenu = useMemo(
    () =>
      generalBatchActionContextMenu.filter((i) =>
        shortlistBatchActionContextMenuItems.includes(i.label!)
      ),

    [generalBatchActionContextMenu, shortlistBatchActionContextMenuItems]
  );

  const longlistBatchActionContextMenu = useMemo(() => {
    const isAbleToSendInterview =
      checkSelectedTableRowsBatchActionStatus(
        Video_Interview_Type_Enum.CompetencyAssessment
      ) && projectsInterviewTemplateId;
    const isAbleToSendToShortlist = checkSelectedTableRowsAllScored();

    const isAbleToSendEnglishTest = checkSelectedTableRowsBatchActionStatus(
      Video_Interview_Type_Enum.EnglishAssessment
    );

    const isContextMenuIncludesSendTest = generalBatchActionContextMenu.find(
      (item) => item.label === i18n.t("projects.inviteToTestInterview")
    );

    const isContextMenuIncludesSendInterview =
      generalBatchActionContextMenu.find(
        (item) => item.label === i18n.t("projects.generateLinkAndSend")
      );

    const isContextMenuIncludesSendToShortList =
      generalBatchActionContextMenu.find(
        (item) => item.label === i18n.t("projects.sendToShortlist")
      );
    const selectedIds = selectedTableRows
      .filter((row) => row.checked)
      .map((row) => row.id);

    const selectedCandidateProjectIds = candidateInformationData
      ?.filter((item) => selectedIds.includes(item.id))
      .map((item) => item.candidateProjectId);

    const filteredCcpIds =
      candidateInformationData
        ?.filter((candidate) => selectedIds.includes(candidate.id))
        .map((candidate) => candidate.ccpId) ?? [];

    if (isAbleToSendInterview && !isContextMenuIncludesSendInterview) {
      generalBatchActionContextMenu.unshift({
        iconName: "Link",
        label: i18n.t("projects.generateLinkAndSend"),
        onClick: () =>
          handleOpenModal(
            i18n.t("candidate.batchActionInterviewInvitationTitle"),
            <Trans
              i18nKey={i18n.t("candidate.batchActionSendInvitationMessage")}
              values={{
                selectedRowsCount: selectedTableRowsCount,
              }}
              components={{
                bold: <NSCandidateListStyle.BatchActionBoldText />,
              }}
            />,
            i18n.t("general.send"),
            i18n.t("general.giveUp"),
            () =>
              handleClickGenerateAndSendLinkButton(
                filteredCcpIds,
                Video_Interview_Type_Enum.CompetencyAssessment
              )
          ),
      });
    }

    if (!isContextMenuIncludesSendTest) {
      generalBatchActionContextMenu.unshift({
        iconName: "Link",
        label: i18n.t("projects.inviteToTestInterview"),
        cascadingItem: [
          {
            label: i18n.t("projects.englishTest"),
            onClick: () =>
              handleClickGenerateAndSendLinkButton(
                selectedCandidateProjectIds as number[],
                Video_Interview_Type_Enum.EnglishAssessment
              ),
          },
        ],
      });
    }

    const disabilityConditions = {
      [i18n.t("projects.englishTest")]: !isAbleToSendEnglishTest,
    };

    if (!isAbleToSendEnglishTest) {
      generalBatchActionContextMenu.forEach((option) => {
        option.cascadingItem?.forEach((cascadingItem) => {
          if (
            disabilityConditions.hasOwnProperty(cascadingItem.label as string)
          ) {
            cascadingItem.isDisabled =
              disabilityConditions[cascadingItem.label as string];
          }
        });
      });
    }

    if (isAbleToSendToShortlist && !isContextMenuIncludesSendToShortList) {
      generalBatchActionContextMenu.unshift({
        iconName: "SendShortlist",
        label: i18n.t("projects.sendToShortlist"),
        onClick: () => {
          handleOpenModal(
            i18n.t("candidate.batchActionShortlistTitle"),
            <Trans
              i18nKey={i18n.t("candidate.batchActionShortlistMessage")}
              values={{
                selectedRowsCount: selectedTableRowsCount,
              }}
              components={{
                bold: <NSCandidateListStyle.BatchActionBoldText />,
              }}
            />,
            i18n.t("general.send"),
            i18n.t("general.giveUp"),
            () =>
              handleChangeCandidateStatus(
                selectedCandidateProjectIds as number[],
                Client_Candidate_Project_Candidate_List_Status_Enum.InShortlist
              )
          );
        },
      });
    }

    return generalBatchActionContextMenu.filter((i) =>
      longlistBatchActionContextMenuItems.includes(i.label!)
    );
  }, [
    generalBatchActionContextMenu,
    longlistBatchActionContextMenuItems,
    projectsInterviewTemplateId,
    selectedTableRows,
  ]);

  const appliedlistBatchActionContextMenu = useMemo(
    () =>
      generalBatchActionContextMenu.filter((i) =>
        appliedlistBatchActionContextMenuItems.includes(i.label!)
      ),

    [generalBatchActionContextMenu, appliedlistBatchActionContextMenuItems]
  );

  const batchActionContextMenu = {
    appliedlist: appliedlistBatchActionContextMenu,
    longlist: longlistBatchActionContextMenu,
    shortlist: shortlistBatchActionContextMenu,
  };

  const generalCandidateData: NSTableListType.ITableListItem[] = useMemo(
    () =>
      candidateInformationData?.map((item) => {
        const tableContextMenuOptions = tableContextMenu[type].map(
          (option) => ({
            ...option,
            cascadingItem: option.cascadingItem?.map((cascadingOption) => ({
              ...cascadingOption,
            })),
          })
        );

        const isContextMenuIncludesSendToShortList =
          generalTableContextMenu.find(
            (item) => item.label === i18n.t("projects.sendToShortlist")
          );

        if (type === "longlist" || type === "shortlist") {
          const buttonByStatus = shownButtonByStatus[item.competencyLastStatus];
          const sendTestInterviewButton = {
            iconName: "Link",
            label: i18n.t("projects.inviteToTestInterview"),
            cascadingItem: [
              {
                label: i18n.t("projects.englishTest"),
                onClick: () =>
                  handleClickGenerateAndSendLinkButton(
                    [item.ccpId],
                    Video_Interview_Type_Enum.EnglishAssessment
                  ),
              },
            ],
          };

          if (type === "longlist") {
            tableContextMenuOptions.splice(2, 0, {
              iconName: sendTestInterviewButton.iconName as keyof typeof icons,
              label: sendTestInterviewButton.label,
              cascadingItem: sendTestInterviewButton.cascadingItem,
            });
          }

          if (
            item.englishTestLastStatus !== Video_Interview_Status_Enum.Scored &&
            item.englishTestLastStatus !==
              Video_Interview_Status_Enum.VideoReceived &&
            item.englishTestLastStatus !== Video_Interview_Status_Enum.VideoSent
          ) {
            const indexToRemove = tableContextMenuOptions.findIndex(
              (item) => item.label === i18n.t("projects.englishTestDetail")
            );
            if (indexToRemove !== -1) {
              tableContextMenuOptions.splice(indexToRemove, 1);
            }
          }

          if (
            buttonByStatus.button === rescoringButton ||
            buttonByStatus.button === startScoringButton
          ) {
            tableContextMenuOptions.map((option) => {
              if (
                option.label === i18n.t("projects.competencyInterviewDetail")
              ) {
                option.cascadingItem?.splice(0, 0, {
                  iconName: buttonByStatus.button.prefixIcon,
                  label: buttonByStatus.button.label as string,
                  onClick: () =>
                    buttonByStatus.action([item.ccpId], item.interviewId),
                });
              }
            });
          } else {
            const indexToRemove = tableContextMenuOptions.findIndex(
              (item) =>
                item.label === i18n.t("projects.competencyInterviewDetail")
            );
            if (indexToRemove !== -1) {
              tableContextMenuOptions.splice(indexToRemove, 1);
            }
            if (type === "longlist") {
              // @ts-ignore
              tableContextMenuOptions.splice(1, 0, {
                iconName: buttonByStatus.button.prefixIcon,
                label: buttonByStatus.button.label as string,
                onClick: () =>
                  buttonByStatus.action(
                    [item.ccpId],
                    Video_Interview_Type_Enum.CompetencyAssessment
                  ),
              });
            }
          }

          if (
            (item.competencyLastStatus === Video_Interview_Status_Enum.Scored ||
              item.englishTestLastStatus ===
                Video_Interview_Status_Enum.Scored) &&
            !isContextMenuIncludesSendToShortList &&
            type === "longlist"
          ) {
            // @ts-ignore
            tableContextMenuOptions.unshift({
              iconName: "SendShortlist",
              label: i18n.t("projects.sendToShortlist"),
              onClick: (_index, id) => {
                const candidateProjectId = candidateInformationData?.find(
                  (item) => item.id === id
                )?.candidateProjectId;
                handleChangeCandidateStatus(
                  [candidateProjectId] as number[],
                  Client_Candidate_Project_Candidate_List_Status_Enum.InShortlist
                );
              },
            });
          }
        }

        const candidateInfo = clientCandidate?.client_candidate_project.find(
          (project) => project.client_candidate_id === item.id
        );

        const isInterviewScored = candidateInfo?.video_interviews?.some(
          (interview) =>
            interview.video_interview_statuses?.some(
              (status) =>
                status.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.CompetencyAssessment &&
                status.status === Video_Interview_Status_Enum.Scored
            )
        );

        const isEnglistTestScored = candidateInfo?.video_interviews?.some(
          (interview) =>
            interview.video_interview_statuses?.some(
              (status) =>
                status.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.EnglishAssessment &&
                status.status === Video_Interview_Status_Enum.Scored
            )
        );

        const hasEnglishTestVideo = candidateInfo?.video_interviews.some(
          (item) =>
            item.video_interview_statuses.some(
              (status) =>
                status.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.EnglishAssessment &&
                (status.status === Video_Interview_Status_Enum.Scored ||
                  status.status === Video_Interview_Status_Enum.VideoReceived ||
                  status.status === Video_Interview_Status_Enum.VideoSent)
            )
        );

        const hasInterviewVideo =
          isInterviewScored ||
          candidateInfo?.video_interviews?.some((interview) =>
            interview.video_interview_statuses?.some(
              (status) =>
                status.video_interview.video_interview_type ===
                  Video_Interview_Type_Enum.CompetencyAssessment &&
                (status.status === Video_Interview_Status_Enum.VideoSent ||
                  status.status === Video_Interview_Status_Enum.VideoReceived)
            )
          );

        const disabilityConditions = {
          [i18n.t("projects.generateLinkAndSend")]:
            !projectsInterviewTemplateId,
          [i18n.t("projects.downloadCv")]:
            !candidateInfo?.client_candidate.cv_file_name,
          [i18n.t("projects.candidateReport")]: !isInterviewScored,
          [i18n.t("projects.viewVideo")]: !hasInterviewVideo,
          [i18n.t("projects.englishTest")]: hasEnglishTestVideo,
        };

        const disabilityConditionsEnglistTest = {
          [i18n.t("projects.downloadCv")]:
            !candidateInfo?.client_candidate.cv_file_name,
          [i18n.t("projects.candidateReport")]: !isEnglistTestScored,
          [i18n.t("projects.viewVideo")]: !hasEnglishTestVideo,
          [i18n.t("projects.englishTest")]: hasEnglishTestVideo,
        };

        tableContextMenuOptions.forEach((option) => {
          if (disabilityConditions.hasOwnProperty(option.label as string)) {
            option.isDisabled = disabilityConditions[option.label as string];
          }

          if (
            option.label === i18n.t("projects.englishTestDetail") ||
            option.label === i18n.t("projects.inviteToTestInterview")
          ) {
            option.cascadingItem?.forEach((cascadingItem) => {
              if (
                disabilityConditionsEnglistTest.hasOwnProperty(
                  cascadingItem.label as string
                )
              ) {
                cascadingItem.isDisabled =
                  disabilityConditionsEnglistTest[
                    cascadingItem.label as string
                  ];
              }
            });
          } else {
            option.cascadingItem?.forEach((cascadingItem) => {
              if (
                disabilityConditions.hasOwnProperty(
                  cascadingItem.label as string
                )
              ) {
                cascadingItem.isDisabled =
                  disabilityConditions[cascadingItem.label as string];
              }
            });
          }
        });

        const candidateId = clientCandidate?.client_candidate_project.find(
          (x) => x.client_candidate_id === item.id
        )?.client_candidate_id;

        const candidateProjectId =
          clientCandidate?.client_candidate_project.find(
            (x) => x.client_candidate_id === item.id
          )?.id;

        return {
          id: item.id ?? "1",
          informations: [
            {
              id: "1",
              information: (
                <Checkbox
                  key={`${item.id}-checkbox`}
                  checked={updateCheckbox(item.id)}
                  onClick={() => handleSelectTableRow(item.id)}
                  icon="CheckWhite"
                />
              ),
            },
            {
              id: "2",
              information: (
                <NSCandidateListStyle.ProjectName>
                  {`${capitalizeFirstLetterHelper(
                    item.name
                  )} ${capitalizeFirstLetterHelper(item.surname)}`}
                  <NSCandidateListStyle.ProjectNameTooltipContainer>
                    {item.otherProjects.length > 0 && (
                      <HistoryTooltip
                        id={`otherProjectTooltip${item.id}`}
                        label={`+${item.otherProjects.length.toString()} ${i18n.t(
                          "projects.project"
                        )}`}
                      >
                        <OtherProject items={item.otherProjects}></OtherProject>
                      </HistoryTooltip>
                    )}

                    {item.notes?.length > 0 && (
                      <NSCandidateListStyle.NoteTooltipBase>
                        <HistoryTooltip
                          id={`noteTooltip${item.id}`}
                          label={`+${item.notes.length.toString()} ${i18n.t(
                            "projects.note"
                          )}`}
                          clickable
                        >
                          <NSCandidateListStyle.NoteTooltipContainer>
                            <NoteList
                              key={item.id}
                              notes={item.notes}
                              handleClickSeeAllNotes={() =>
                                navigate(
                                  `/projects/detail/${projectId}/notes/${candidateId}/${candidateProjectId}/${type}/all-notes`
                                )
                              }
                              handleClickAddNewNote={() =>
                                navigate(
                                  `/projects/detail/${projectId}/notes/${candidateId}/${candidateProjectId}/${type}/add-note`
                                )
                              }
                            />
                          </NSCandidateListStyle.NoteTooltipContainer>
                        </HistoryTooltip>
                      </NSCandidateListStyle.NoteTooltipBase>
                    )}
                  </NSCandidateListStyle.ProjectNameTooltipContainer>
                </NSCandidateListStyle.ProjectName>
              ),
            },
            {
              id: "3",
              information: item.email.toLowerCase(),
            },
            {
              id: "4",
              information: formatPhoneNumberHelper(item.mobileNumber),
            },
            {
              id: "5",
              information:
                item.scoredStatuses && item.scoredStatuses.length >= 1 ? (
                  <NSCandidateListStyle.ScoresTooltipBase>
                    <HistoryTooltip
                      id={`score-history-${item?.id}`}
                      label={i18n.t("projects.scores")}
                      clickable
                    >
                      <OtherProject items={item.scoreHistory}></OtherProject>
                    </HistoryTooltip>
                  </NSCandidateListStyle.ScoresTooltipBase>
                ) : item.status &&
                  item.status !== Video_Interview_Status_Enum.NotSet ? (
                  <StatusBadge
                    label={
                      candidateStatusHelper(
                        item.status as string,
                        item.score.toString()
                      ).label
                    }
                    color={
                      candidateStatusHelper(
                        item.status as string,
                        item.score.toString()
                      ).color
                    }
                    type={item.lastStatusInterviewType}
                  />
                ) : (
                  <NSCandidateListStyle.NotActionStatus>
                    {i18n.t("general.notActionYet")}
                  </NSCandidateListStyle.NotActionStatus>
                ),
            },
            {
              id: "6",
              information:
                item.scoredStatuses && item.scoredStatuses.length > 0 ? (
                  <HistoryTooltip
                    id={`score-history-${item?.id}`}
                    label={i18n.t("projects.scores")}
                  >
                    <OtherProject items={item.scoreHistory}></OtherProject>
                  </HistoryTooltip>
                ) : (
                  <NSCandidateListStyle.NotActionStatus>
                    {i18n.t("general.notScored")}
                  </NSCandidateListStyle.NotActionStatus>
                ),
            },
            {
              id: "7",
              information: (
                <NSCandidateListStyle.CandidateStatusContainer>
                  <StatusBadge
                    label={
                      candidateStatusHelper(
                        item.candidateResolution ??
                          Client_Candidate_Project_Candidate_Resolution_Enum.NoShow
                      ).label
                    }
                    color={
                      candidateStatusHelper(
                        item.candidateResolution ??
                          Client_Candidate_Project_Candidate_Resolution_Enum.NoShow
                      ).color
                    }
                  />
                  <ContextMenuHOC
                    id={String(item.ccpId)}
                    type="basic"
                    items={candidateResolutionContextMenu}
                    customButton={<Icon name="ChevronDown" />}
                  />
                </NSCandidateListStyle.CandidateStatusContainer>
              ),
            },
            {
              id: "8",
              information:
                item.updateHistory.length > 0 ? (
                  <NSCandidateListStyle.UpdateHistoryBase>
                    <HistoryTooltip
                      id={`${item?.id}`}
                      label={`${dayjs(
                        item?.updateHistory[0]?.createdAt ?? ""
                      ).format("DD.MM.YYYY[,] hh:mm")}`}
                      clickable
                    >
                      <UpdateHistory items={item.updateHistory}></UpdateHistory>
                    </HistoryTooltip>
                  </NSCandidateListStyle.UpdateHistoryBase>
                ) : (
                  <NSCandidateListStyle.InvalidDate></NSCandidateListStyle.InvalidDate>
                ),
            },
            {
              id: "9",
              information: (
                <NSTableListStyle.SuspiciousEvent>
                  {((item.competencyLastStatus ===
                    Video_Interview_Status_Enum.Scored &&
                    item.competencySuspiciousActivity.totalEventCount > 0) ||
                    (item.englishTestLastStatus ===
                      Video_Interview_Status_Enum.Scored &&
                      item.englishAssessmentSuspiciousActivity.totalEventCount >
                        0)) && (
                    <HistoryTooltip
                      id={`${item?.id}-suspicious-event`}
                      label={<Icon name="WarningCircle" />}
                    >
                      <NSTableListStyle.SuspiciousEventLabel>
                        <NSTableListStyle.SuspiciousEventWarningText>
                          {(item?.competencySuspiciousActivity?.questionOrders
                            ?.length > 0 ||
                            item.englishAssessmentSuspiciousActivity
                              .questionOrders?.length > 0) && (
                            <Trans
                              i18nKey="candidate.suspiciousActivityWarningText"
                              values={{
                                questions:
                                  item.competencySuspiciousActivity.questionOrders
                                    .map((i) => i)
                                    .join(", "),
                              }}
                              components={{
                                bold: (
                                  <NSCandidateListStyle.SuspiciousActivityBoldText />
                                ),
                              }}
                            />
                          )}
                        </NSTableListStyle.SuspiciousEventWarningText>

                        <NSTableListStyle.SuspiciousEventQuestions>
                          {(item.competencyLastStatus ===
                            Video_Interview_Status_Enum.Scored ||
                            item.englishTestLastStatus ===
                              Video_Interview_Status_Enum.Scored) &&
                            item.competencySuspiciousActivity.questionOrders
                              .length > 0 && (
                              <div>
                                {i18n.t("projects.competencyInterview") +
                                  ": " +
                                  Array.from(
                                    new Set(
                                      item.competencySuspiciousActivity.questionOrders
                                    )
                                  )
                                    .map((q) => q)
                                    .join(", ")}
                              </div>
                            )}

                          {(item.englishTestLastStatus ===
                            Video_Interview_Status_Enum.Scored ||
                            item.competencyLastStatus ===
                              Video_Interview_Status_Enum.Scored) &&
                            item.englishAssessmentSuspiciousActivity
                              .questionOrders.length > 0 && (
                              <div>
                                {i18n.t("projects.englishTest") +
                                  ": " +
                                  Array.from(
                                    new Set(
                                      item.englishAssessmentSuspiciousActivity.questionOrders
                                    )
                                  )
                                    .map((q) => q)
                                    .join(", ")}
                              </div>
                            )}
                        </NSTableListStyle.SuspiciousEventQuestions>
                      </NSTableListStyle.SuspiciousEventLabel>
                    </HistoryTooltip>
                  )}
                </NSTableListStyle.SuspiciousEvent>
              ),
            },
            {
              id: "10",
              information: (
                <NSTableListStyle.ContextMenuColumn>
                  <NSTableListStyle.TransactionsText>
                    {i18n.t("candidate.transactions")}
                  </NSTableListStyle.TransactionsText>
                  {tableContextMenuOptions &&
                    tableContextMenuOptions.length > 0 && (
                      <ContextMenuHOC
                        id={item.id}
                        type="cascading"
                        items={tableContextMenuOptions}
                      />
                    )}
                </NSTableListStyle.ContextMenuColumn>
              ),
            },
          ],
        };
      }) ?? [],
    [candidateInformationData, isAllTableRowsSelected, selectedTableRows]
  );

  const appliedlistCandidateData = generalCandidateData.map((item) => {
    const filteredInformations = item.informations?.filter(
      (info) => info.id !== "6" && info.id !== "7" && info.id !== "9"
    );

    return {
      ...item,
      informations: filteredInformations,
    };
  });

  const longListCandidateData = generalCandidateData.map((item) => {
    const filteredInformations = item.informations?.filter(
      (info) => info.id !== "6" && info.id !== "7"
    );

    return {
      ...item,
      informations: filteredInformations,
    };
  });

  const shortlistCandidateData = generalCandidateData.map((item) => {
    const filteredInformations = item.informations?.filter(
      (info) => info.id !== "5" && info.id !== "8" && info.id !== "9"
    );

    return {
      ...item,
      informations: filteredInformations,
    };
  });

  const candidateData = useMemo(
    () => ({
      appliedlist: appliedlistCandidateData,
      longlist: longListCandidateData,
      shortlist: shortlistCandidateData,
    }),

    [generalCandidateData]
  );

  useEffect(() => {
    clearCheckBoxStates();
  }, [pathname, pagination, candidateInformationData]);

  const headerItemListCheckBoxIcon = useMemo(() => {
    const totalCheckedItems = selectedTableRows.filter(
      (item) => item.checked
    ).length;
    const totalItems = generalCandidateData.length;
    return totalCheckedItems === totalItems ? "CheckWhite" : "CheckBoxDelete";
  }, [selectedTableRows, generalCandidateData, type]);

  const longlistHeaderItemList = [
    <NSCandidateListStyle.HeaderCheckboxContainer key="headerCheckbox">
      <Checkbox
        checked={isAllTableRowsSelected}
        onClick={handleSelectAllTableRows}
        icon={headerItemListCheckBoxIcon}
      />
    </NSCandidateListStyle.HeaderCheckboxContainer>,
    i18n.t("projects.candidateName"),
    i18n.t("projects.emailAddress"),
    i18n.t("projects.mobile"),
    i18n.t("projects.interviewStatus"),
    i18n.t("projects.lastUpdateDate"),
    "",
    <NSCandidateListStyle.DownloadListButtonContainer>
      <NSCandidateListStyle.DownloadListButton
        size="small"
        label={i18n.t("candidate.downloadList")}
        onClick={handleDownloadListButton}
      />
    </NSCandidateListStyle.DownloadListButtonContainer>,
  ];

  const appliedListHeaderItemList = [
    <NSCandidateListStyle.HeaderCheckboxContainer key="headerCheckbox">
      <Checkbox
        checked={isAllTableRowsSelected}
        onClick={handleSelectAllTableRows}
        icon={headerItemListCheckBoxIcon}
      />
    </NSCandidateListStyle.HeaderCheckboxContainer>,
    i18n.t("projects.candidateName"),
    i18n.t("projects.emailAddress"),
    i18n.t("projects.mobile"),
    i18n.t("projects.interviewStatus"),
    i18n.t("projects.lastUpdateDate"),
    <NSCandidateListStyle.DownloadListButtonContainer
      key={"downloadListButton"}
    >
      <NSCandidateListStyle.DownloadListButton
        size="small"
        label={i18n.t("candidate.downloadList")}
        onClick={handleDownloadListButton}
      />
    </NSCandidateListStyle.DownloadListButtonContainer>,
  ];

  const shortlistHeaderItemList = [
    <NSCandidateListStyle.HeaderCheckboxContainer key="headerCheckbox">
      <Checkbox
        checked={isAllTableRowsSelected}
        onClick={handleSelectAllTableRows}
        icon={headerItemListCheckBoxIcon}
      />
    </NSCandidateListStyle.HeaderCheckboxContainer>,
    i18n.t("projects.candidateName"),
    i18n.t("projects.emailAddress"),
    i18n.t("projects.mobile"),
    i18n.t("general.score"),
    i18n.t("projects.candidateStatus"),
    <NSCandidateListStyle.DownloadListButtonContainer>
      <NSCandidateListStyle.DownloadListButton
        size="small"
        label={i18n.t("candidate.downloadList")}
        onClick={handleDownloadListButton}
      />
    </NSCandidateListStyle.DownloadListButtonContainer>,
  ];

  const headerItemList = {
    appliedlist: appliedListHeaderItemList,
    longlist: longlistHeaderItemList,
    shortlist: shortlistHeaderItemList,
  };

  const longlistnotFound = (
    <NSCandidateListStyle.NotFoundContainer>
      <NSCandidateListStyle.NotFoundUpper>
        <NSCandidateListStyle.NotFoundText>
          {searchString ? (
            <Trans
              i18nKey={i18n.t("general.noRecordWithValue")}
              values={{
                search: searchString,
              }}
            />
          ) : (
            i18n.t("general.noRecord")
          )}
        </NSCandidateListStyle.NotFoundText>

        <NSCandidateListStyle.AddCandidateContainer>
          <SolidButton
            onClick={handleClickAddCandidate}
            label={i18n.t("projects.addCandidate")}
          />
          <NSCandidateListStyle.AddCandidateContainerText>
            {i18n.t("projects.or")}
          </NSCandidateListStyle.AddCandidateContainerText>
          <NSCandidateListStyle.UploadExcelButton
            onClick={handleClickAddCandidateFromExcel}
            label={i18n.t("projects.uploadFromExcel")}
          />
        </NSCandidateListStyle.AddCandidateContainer>
      </NSCandidateListStyle.NotFoundUpper>
    </NSCandidateListStyle.NotFoundContainer>
  );

  const shortlistAndAppliedlistNotFound = (
    <NSCandidateListStyle.NotFoundContainer>
      <NSCandidateListStyle.NotFoundUpper>
        <NSCandidateListStyle.NotFoundText>
          {searchString ? (
            <Trans
              i18nKey={i18n.t("general.noRecordWithValue")}
              values={{
                search: searchString,
              }}
            />
          ) : (
            i18n.t("general.noRecord")
          )}
        </NSCandidateListStyle.NotFoundText>
      </NSCandidateListStyle.NotFoundUpper>
    </NSCandidateListStyle.NotFoundContainer>
  );

  const notFound = {
    appliedlist: shortlistAndAppliedlistNotFound,
    longlist: longlistnotFound,
    shortlist: shortlistAndAppliedlistNotFound,
  };

  const selectedTableRowsCount = useMemo(() => {
    return selectedTableRows.filter((item) => item.checked).length;
  }, [selectedTableRows]);

  return {
    notFound,
    isLoading,
    headerItemList,
    candidateData,
    totalItemCount:
      clientCandidate?.client_candidate_aggregate.aggregate?.count ?? 0,
    pagination,
    selectedTableRowsCount,
    batchActionContextMenu,
    modalContent,
    handleSetPagination,
    onClickModalCloseButton,
    clearCheckBoxStates,
  };
};

export default useCandidateListVm;
