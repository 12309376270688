export const TR = "tr";
export const EN = "en";
export const LOGIN_USER_INFO = "LOGIN_USER_INFO";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const DEFAULT_LOCALE = EN;
export const LANGUAGE_INFO = "LOCALE";
export const CV = "cv";
export const AVATARS = "avatars";
export const PUT = "put";
export const GET = "get";
export const PAGINATION_DEFAULT_LIMIT = 20;
export const CANDIDATE_APPLIEDLIST = "appliedlist";
export const CANDIDATE_SHORTLIST = "shortlist";
export const CANDIDATE_LONGLIST = "longlist";
export const DEFAULT_PAGINATION_OPTIONS = [
  {
    id: 1,
    label: "5",
  },
  {
    id: 2,
    label: "10",
  },
  {
    id: 3,
    label: "15",
  },
  {
    id: 4,
    label: "20",
  },
  {
    id: 5,
    label: "25",
  },
  {
    id: 6,
    label: "30",
  },
  {
    id: 7,
    label: "35",
  },
  {
    id: 8,
    label: "40",
  },
  {
    id: 9,
    label: "100",
  },
  {
    id: 10,
    label: "250",
  },
];
