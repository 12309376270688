import styled from "styled-components";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSPaginationStyle {
  export const Container = styled.div`
    display: flex;
    font-family: greycliff-cf, sans-serif;
    align-items: center;
  `;

  export const Label = styled.label``;
  export const SecondLabel = styled(Label)``;

  export const DropdownContainer = styled.div`
    width: 90px;
    margin: 0 32px 0 24px;

    ${NSDropdownStyle.InnerDropdownTitle} {
      padding: 4px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
    }
  `;

  export const IconContainer = styled.div`
    display: flex;
    margin-left: 12px;
    ${NSMainButtonStyle.ButtonBase} {
      padding: 0;
      svg {
        fill: transparent;
      }
    }
  `;
}
